.project-plan {
  div {
    width: 300px;
    margin: auto;
  }

  p {
    border-top: 2px dashed;
    border-color: #0f0f0f !important;
    margin: 0;
    padding: 30px;
    counter-increment: section;
    position: relative;
  }


  p:nth-child(even):before {
    content: counter(section);
    right: 100%;
    margin-right: -20px;
    position: absolute;
    border-radius: 50%;
    padding: 10px;
    height: 50px;
    width: 50px;
    background-color: #0f0f0f;
    text-align: center;
    color: white;
    font-size: 110%;
  }

  p:nth-child(odd):before {
    content: counter(section);
    left: 100%;
    margin-left: -20px;
    position: absolute;
    border-radius: 50%;
    padding: 10px;
    height: 50px;
    width: 50px;
    background-color: #0f0f0f;
    text-align: center;
    color: white;
    font-size: 110%;
  }


  p:nth-child(even) {
    border-left: 2px dashed;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-right: 30px;
    padding-right: 0;
  }

  p:nth-child(odd) {
    border-right: 2px dashed;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: 30px;
    padding-left: 0;
  }

  p:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  p:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}