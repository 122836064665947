@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/scss/mdb";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "./styles/home-page";
@import "./styles/project-plan";

@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
$animate: all 0.6s ease-in-out;
$c1: mediumturquoise;
$c2: mediumpurple;
$g1: linear-gradient(to right, $c1, $c2);


$font: 'Bebas Neue', cursive;
$font-color: #ffffff;
$background: #b6dfe0;
$transition: .6s;
$font-size: 5vw;

.brand {
  font-family: 'Megrim', cursive;
  font-size: 300%;
  font-weight: 600;
  color: #0f0f0f !important;
}

@keyframes stretch {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

@keyframes stretch-intercept {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    opacity: 0.5;
    width: 100%;
  }
}

@keyframes bolden {
  from {
    font-size: 100%
  }
  to {
    font-size: 120%;
  }
}

.animated-large-name {
  font-size: 250%;
  font-family: 'Bebas Neue', cursive;
  position: relative;
  right: 0;

  &:before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 5px;
    margin: 5px 0 0;
    animation: stretch;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    background-color: darken(#000000, 5%);
  }
}

.animated-large-text {
  font-size: 120%;
  font-family: 'Bebas Neue', cursive;
  position: relative;
  right: 0;
}


.inline-text-underline {
  position: relative;
  right: 0;

  &:before {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 5px;
    margin: 1px 0 0;
    animation: stretch;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    background-color: darken(#e0b762, 5%);
  }
}

.inline-text-underline-red {
  position: relative;
  right: 0;

  &:before {
    content: "";
    position: absolute;
    bottom: 5px;
    height: 7px;
    margin: 1px 0 0;
    animation: stretch-intercept;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    background-color: darken(#e06757, 5%);
  }
}

.inline-text-bolden {
  animation: bolden;
  animation-duration: 5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.inline-text-overline-underline {
  position: relative;
  right: 0;
  font-family: 'Bebas Neue', cursive;
  color: #8CA3D1;

  &:before {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 5px;
    margin: 1px 0 0;
    animation: stretch;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    background-color: darken(#8CA3D1, 5%);
  }

  &:after {
    content: "";
    position: absolute;
    top: -4px;
    height: 5px;
    margin: 1px 0 0;
    animation: stretch;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    background-color: darken(#8CA3D1, 5%);
    animation-direction: normal;
    right: 0;
  }
}

.bg-highlight {
  font-style: normal;
  position: relative;
  display: inline;
  box-decoration-break: clone;
  padding: 0.1em 0.2em;
  border-radius: 0.2em;
  background: white;
  background: $g1;
  animation: stretch;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  animation-duration: 5s;
  transform: skewX(-10deg); // this is ignored on inline elements, in Webkit at least.
}

.text-reveal {
  position: relative;
  right: 0;
  font-family: 'Bebas Neue', cursive;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    height: 20px;
    margin: 1px 0 0;
    animation: stretch;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-direction: alternate;
    animation-delay: 0s;
    background-color: darken(#e0b762, 5%);
  }
}

.share > span {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background: lightgrey;
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 0;
    top: 0;
    left: 0;
    transition: $transition;
    z-index: -5;
  }


}

.share:hover > span {
  background: none;
}

@for $i from 1 through 14 {

  .share:hover > span:nth-child(#{$i}):after {
    $delay: 100 * $i;
    $random: ($i * 5) - 30;
    background: $background;
    width: 100%;
    transition: $transition;
    transition-delay: $delay + ms;
    transform: rotate($random + deg);
  }
  .share:hover > span:nth-child(#{$i}) {
    $delayTwo: 110 * $i;
    color: whitesmoke;
    transition-delay: $delayTwo + ms;
  }
}

@keyframes heightadjuster {
  from {height: 100vh;}
  to {height: 65vh;}
}

.adjust-height {
  animation: heightadjuster 3s;
  animation-fill-mode: forwards;
}

.brand-center {
  font-family: 'Megrim', cursive;
  font-size: 600%;
  font-weight: 600;
  color: #0f0f0f !important;
}


.header-text {
  font-size: large;
  font-family: 'Khand', sans-serif;
  color: #0f0f0f !important;
}

.section-header {
  @extend .mb-3;
  font-family: 'Khand', sans-serif;
}


.special-text {
  font-family: 'Khand', sans-serif;
}

.section-item {
  @extend .mb-2;
  font-family: 'Khand', sans-serif;
}

.section-item:hover {
  font-size: large;
}

.most-height {
  height: 58vh;
}

.full-page-height {
  height: 90vh;
}

.cut-bottom-height {
  height: 85vh;
}

.h-97 {
  height: 97vh;
}

.vh-50 {
  height: 50vh
}

.who-we-are-body{
  min-height: 61vh;
}

.full-page-height-flex {
  min-height: 90vh;
}

.card-full-page-height-flex {
  min-height: 100vh;
}

.submit-page-height {
  height: 80vh;
}

.submit-height-flex {
  min-height: 80vh;
}


.plain-navbar {
  .navbar {
    box-shadow: none;
  }
}

.page-themed-navbar {
  background-image: url('./images/about-page-2.png') !important;
  background-repeat: round;

  .navbar {
    box-shadow: none;
  }
}

.theme-background {
  background: #e0b762;
}

.manifesto-header {
  border: 2px solid rgba(0, 0, 0, 0.92);
  font-family: 'Khand', sans-serif;
}

.cards-section {
  background-image: url('./images/home-page-card-bg.png') !important;
  background-repeat: repeat-x;

  img {
    @extend .img-fluid
  }
}

@media (hover: hover) {
  .slideUp:hover {
    position: relative;
    top: -2vh;
  }
}

.special-text-area div {
  @extend .special-text
}

.gradient-background {
  background-color: rgba(0, 0, 0, 0.4);

}


@media screen and (max-width: 700px) {
  .resizable-brand {
    width: 25%;
    height: 25%;
  }

  .animated-large-name {
    font-size: 200%;
  }

  .c-text-large {
    font-size: 150%;
  }

}

@media screen and (min-width: 701px) {
  .resizable-brand {
  }

  .animated-large-name {
    font-size: 250%;
  }

  .c-text-large {
    font-size: 200%;
  }
}

.section-text {
  color: #4f4f4f !important;
  font-size: 110%;
  font-family: 'Arima Madurai', cursive;
}


.blockLink {
  display: block;
  margin: auto;
  padding: 1rem 1.25rem;
  font-family: 'Bebas Neue', cursive;
  text-decoration: none;
  color: white;
  position: relative;
  transition: all .3s cubic-bezier(.2, 0, 0, 1);
  z-index: 1;

  &:after {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    bottom: 0;
    right: 1.25rem;
    left: 1.25rem;
    background-color: #ffffff;
    transition: all .3s cubic-bezier(.2, 0, 0, 1);
    transform-origin: bottom center;
    z-index: -1;
  }

  &:hover {
    color: #2D2D2D;

    &:after {
      right: 0;
      left: 0;
      height: 100%;
    }
  }
}

.md-form input{
  border-color: #0f0f0f !important;
}


.dropdown-content {
  max-height: 20vh;
  background-color: #e0b762;

  & > li {
    color: #0f0f0f !important;
  }

  & > li > a, & > li > span {
    color: #0f0f0f !important;
  }

  & > li.active {
    background-color: #0f0f0f !important;
  }

  & > li:hover {
    background-color: #0f0f0f !important;
  }
}

.mdb-main-label {
  color: #6c757d !important;
}

.md-form .prefix.active {
  color: whitesmoke !important;
}

.md-form input:not(.browser-default):focus:not([readonly]) + label {
  color: #6c757d !important;
}